import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['s', 'm', 'a', 'r', 't', 'objective', 'category', 'submit']

  initialize() { }

  connect() { 
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add('btn-secondary')
  }

  checkDone(event) {
    if (this.allChecked()) {
      this.submitTarget.classList.remove('btn-secondary')
      this.submitTarget.classList.add('btn-primary')
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
      this.submitTarget.classList.remove('btn-primary')
      this.submitTarget.classList.add('btn-secondary')
    }
  }

  allChecked() {
    let result = this.sTarget.checked && this.mTarget.checked && this.aTarget.checked && this.rTarget.checked && this.tTarget.checked 
    
    if (this.hasObjectiveTarget) {
      result = result && this.objectiveTarget.checked 
    }

    if (this.hasCategoryTarget) {
      result = result && this.categoryTarget.checked 
    }

    return result
  }

}
