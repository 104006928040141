import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["new", "completed", "enrolled", "newFilterButton", "completedFilterButton", "enrolledFilterButton", "allFilterButton"];

  connect() {
    console.log("filter ready for duty")
    this.activate(this.allFilterButtonTarget)
  }

  newFilter(event) {
    this.activate(this.newFilterButtonTarget)

    this.show(this.newTargets)
    this.hide(this.completedTargets)
    this.hide(this.enrolledTargets)
  }

  completedFilter(event) {
    this.activate(this.completedFilterButtonTarget)

    this.show(this.completedTargets)
    this.hide(this.newTargets)
    this.hide(this.enrolledTargets)
  }

  enrolledFilter(event) {
    this.activate(this.enrolledFilterButtonTarget)

    this.show(this.enrolledTargets)
    this.hide(this.newTargets)
    this.hide(this.completedTargets)
  }

  reset(event) {
    this.deactivateAll()

    this.show(this.newTargets)
    this.show(this.completedTargets)
    this.show(this.enrolledTargets)

    this.activate(this.allFilterButtonTarget)
  }

  // helpers
  hide(targets) {
    targets.forEach( t => {
      t.classList.add('d-none')
    })
  }

  show(targets) {
    targets.forEach( t => {
      t.classList.remove('d-none')
    })
  }

  activate(el) {
    // deactivate everything
    this.deactivateAll()

    // activate the one that was clicked
    el.classList.add("btn-secondary")
  }

  deactivateAll() {
    this.newFilterButtonTarget.classList.remove("btn-secondary")
    this.enrolledFilterButtonTarget.classList.remove("btn-secondary")
    this.completedFilterButtonTarget.classList.remove("btn-secondary")
    this.allFilterButtonTarget.classList.remove("btn-secondary")
  }

}
