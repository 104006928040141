import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['date', 'startAtTime', 'startAt', 'endAtTime', 'endAt', 'copy', 'previous']

  connect() { 
    if (this.hasCopyTarget && this.previousTarget.value) {
      this.copyTarget.classList.remove('d-none')
    }
  }

  zeroPad(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  changeDate(event) {
    this.recalculateTimes()
  }

  changeStartAt(event) {
    // the time is the important part (we're going to throw away the y/m/d)
    var datetime = new Date('1970-01-01T' + this.startAtTimeTarget.value + 'Z')

    // add 30 minutes by default to the meeting duration
    datetime.setMinutes(datetime.getUTCMinutes() + 30)
    this.endAtTimeTarget.value = this.zeroPad(datetime.getUTCHours()) + ':' + this.zeroPad(datetime.getUTCMinutes())

    this.recalculateTimes()
  }

  changeEndAt(event) {
    this.recalculateTimes()
  }

  recalculateTimes(event) {
    let date = '1970-01-01T'
    if (this.dateTarget.value) {
      date = this.dateTarget.value + 'T'
    }

    if (this.startAtTimeTarget.value) {
      this.startAtTarget.value = new Date(date + this.startAtTimeTarget.value).toISOString()
    }

    if (this.endAtTimeTarget.value) {
      this.endAtTarget.value = new Date(date + this.endAtTimeTarget.value).toISOString()
    }
  }

  previous(event) {
    if (event.target.value) {
      this.copyTarget.classList.remove('d-none')
    } else {
      this.copyTarget.classList.add('d-none')
    }
  }
}
