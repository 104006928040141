import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["meetingItems", "template"]

  initialize() { }

  connect() { }

  add(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML //.replace(/NEW_RECORD/g, new Date().getTime())
    this.meetingItemsTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()

    event.target.closest(".meeting-item").remove()
  }

}
