import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list"]

  connect() { }

  filterByUser(event) {
    event.preventDefault
    const userId = event.target.closest("a").dataset.goalsFilterUser

    // show everything
    const allGoals = this.listTarget.children
    for (const goal of allGoals) {
      goal.classList.remove('d-none');
    }

    // hide everything that isn't the selected user
    const userGoals = this.listTarget.querySelectorAll(`a[data-goal-user]:not([data-goal-user="${userId}"])`)
    for (const goal of userGoals) {
      goal.classList.add('d-none');
    }

    // make sure we can see the list
    this.listTarget.scrollIntoView({
      behavior: 'smooth', // smooth scroll
      block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    })
  }

  reset(event) {
    event.preventDefault()
    const goals = this.listTarget.children
    for (const goal of goals) {
      goal.classList.remove('d-none');
    }
  }

  filterByCategory(event) {
    event.preventDefault()
    const category = event.target.closest("a").dataset.goalsCategory

    // show everything
    const allGoals = this.listTarget.children
    for (const goal of allGoals) {
      goal.classList.remove('d-none');
    }

    // hide everything that isn't the selected user
    const goals = this.listTarget.querySelectorAll(`a[data-goal-category]:not([data-goal-category="${category}"])`)
    for (const goal of goals) {
      goal.classList.add('d-none');
    }

    // make sure we can see the list
    this.listTarget.scrollIntoView({
      behavior: 'smooth', // smooth scroll
      block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
    })
  }
}
