import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const button = document.getElementById("scroll-btn")

    function show() {
      button.classList.remove("d-none");
    }
    function hide() {
      button.classList.add("d-none")
    }

    window.addEventListener("scroll", (function scroll() {
      const y = window.scrollY;

      if (y > 100) {
        show();
      } else {
        hide();
      }
    }
    ));
  } 
  
  up() {
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
    });
  }

}
