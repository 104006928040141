import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form', 'text']

  initialize() { }

  connect() { }

  toggle(event) {
    event.preventDefault()
    this.formTarget.classList.toggle('d-none')
    this.textTarget.classList.toggle('d-none')
  }

}
