import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
export default class extends Controller {
  static targets = ["checkbox"]

  static values = {
    url: String,
    wrapper: String
  }

  async toggle(event) {
    let data = {} 
    data[this.wrapperValue] = { 'status': 'complete' }
    // make the request with request.js
    const request = new FetchRequest('patch', this.urlValue, { responseKind: 'json', body: JSON.stringify(data) })
    const response = await request.perform()
    if (response.ok) {
      // const body = await response.text
      const json = await response.json
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      window.notifier.success(`Item marked as ${json.status}`, {})
    } else {
      this.checkboxTarget.checked = false;
    }
  }

  async notApplicable(event) {
    let data = {} 
    data[this.wrapperValue] = { 'status': 'not_applicable' }
    // make the request with request.js
    const request = new FetchRequest('patch', this.urlValue, { responseKind: 'json', body: JSON.stringify(data) })
    const response = await request.perform()
    if (response.ok) {
      // const body = await response.text
      const json = await response.json
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      window.notifier.success(`Item marked as ${json.status}`, {})
    } else {
      this.checkboxTarget.checked = false;
    }
  }
}
