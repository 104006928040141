// Controller for drag and drop function in Legs
import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static values = {
    param: String,
    reloadOnSuccess: Boolean
  }
  static targets = ["list"]

  connect() {
    if (this.hasListTarget) {
      this.sortable = Sortable.create(this.listTarget, {
          cursor: 'pointer !important',
          animation: 150,
          filter: ".drag-filtered",
          disabled: true,
          onEnd: this.end.bind(this)
      })
    }
  }

  // end the drag operation, send the change in position to the server and reload the page
  async end(event) {
    let data = { }
    data[this.paramValue] = event.newDraggableIndex

    let url = event.item.dataset.url

    // make the request with request.js
    const request = new FetchRequest('patch', url, { responseKind: 'json', body: JSON.stringify(data) })
    const response = await request.perform()
    if (response.ok) {
      // const json = await response.json
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      // window.notifier.success(`Item marked as ${data.status}`, {})
      if (this.reloadOnSuccessValue) {
        location.reload() // TODO: wut?
      }
    } else {
      console.error(`[ERROR] ${response.statusCode}`)
    }
  }

  toggle(event){
    event.preventDefault();

    var state = this.sortable.option("disabled");

    if (!state) {
      event.target.innerHTML = "Reorder"
      this.listTarget.querySelectorAll('.drag-handle').forEach(function(item) {
        item.classList.add('d-none')
      });
    } else {
      event.target.innerHTML = "End Reordering"
      this.listTarget.querySelectorAll('.drag-handle').forEach(function(item) {
        item.classList.remove('d-none')
      });
    }

	  this.sortable.option("disabled", !state);
  }
}