import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['url', 'title']

  async fetchTitle(e) {
    e.preventDefault()

    const url = this.urlTarget.value
    if (!url || !this.isValidHttpUrl(url)) {
      window.notifier.warning(`Oops, please provide a valid URL.`, {})
      return
    }

    let data = {url: url} 
    // make the request with request.js
    const request = new FetchRequest('post', '/learning_logs/fetch_title', { responseKind: 'json', body: JSON.stringify(data) })
    const response = await request.perform()
    const json = await response.json

    if (response.ok) {
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      this.titleTarget.value = json.title
    } else {
      window.notifier.warning(`Unable to fetch title for url: ${json.error}`, {})
    }
  }

  isValidHttpUrl(string) {
    try {
      const newUrl = new URL(string);
      return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }
}
